import * as React from 'react'
import { InView } from 'react-intersection-observer';

const Press = () => {
  const [inView, setInView] = React.useState(false)

  return (
    <InView as="section" onChange={setInView} delay="200">
      <div className="press grid">
        <h2 className="press__title h4">In the press</h2>
        <blockquote className="press__quote">
          <div className="press__text h2">
            “What I love most about the Parfait web experience is how seamless it is to navigate the pages. [...] From start to end, Parfait’s experience is rooted in luxury.”
          </div>
          <footer>
            <cite className="press__cite">
              <a className="press__link" href="https://www.elle.com/beauty/hair/a42124697/parfait-wig-ai-technology-review/" target="_blank">
                <span className="sr-only">Elle</span>
              </a>
              <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 737 266" className="css-19h6pwr e1ccpyf90"><path d="M56.8 253.4H47V131.1h4.3c16.7 0 25.5 17.7 25.5 44.7h2.8V81.6h-2.8c0 23-7.8 46.5-25.5 46.5H47V10.4h12.6c19.7 0 41.4 15.9 41.4 58.4h2.8V7.1H.7v3.3h16.4v243H.7v3.3h103.6v-65.2h-2.8c0 46-23.5 61.9-44.7 61.9zm212.7 0h-12.1v-243h18.7V7.1h-67.2v3.3h18.7v242.8h-18.7v3.3H316V191h-3.3c-.7 43.2-18.7 62.4-43.2 62.4zm221.6 0H479v-243h18.7V7.1h-67.2v3.3h18.7v242.8h-18.7v3.3h107.1V191h-3.3c-.8 43.2-18.7 62.4-43.2 62.4zm243.5-61.9v-.3c0 46-23.5 61.9-44.7 61.9H680v-122h4.3c16.7 0 25.5 17.7 25.5 44.7h2.8V81.6h-2.8c0 23-7.8 46.5-25.5 46.5H680V10.4h12.6c20 0 41.4 15.9 41.4 58.4h2.8V7.1h-103v3.3h16.4v243h-16.4v3.3h103.6v-65.2h-2.8z"></path></svg>
            </cite>
          </footer>
        </blockquote>
      </div>
    </InView>
  )
}

export default Press