import * as React from 'react';

import { SplideSlide } from '@splidejs/react-splide';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const TestimonialSlide = ( { testimonial, image }) => {
  return (
    <SplideSlide>
      <div className="testimonial-slide grid">
        <div className="testimonial-slide__image">
          <GatsbyImage
            image={getImage(image)}
            alt={`Mock-ups of ${testimonial.client} project on MacBook and iPhone`}
            loading="lazy"
          />
        </div>
        <div className="testimonial-slide__content">
          {testimonial.date && <div className="testimonial-slide__date-wrapper" style={{"--color-accent": testimonial.accentColor }}>
            <div className="testimonial-slide__accent" aria-hidden="true">
              <div className="testimonial-slide__accent-inner"></div>
            </div>
            <p className="testimonial-slide__date">{testimonial.date}</p>
          </div>
          }
          <blockquote className="testimonial-slide__blockquote">
            <p className="testimonial-slide__quote h2">{testimonial.quote}</p>
            <footer className="testimonial-slide__footer">
              <cite className="testimonial-slide__author">{testimonial.author}</cite>
            </footer>
          </blockquote>
        </div>
      </div>
    </SplideSlide>
  )
}

export default TestimonialSlide