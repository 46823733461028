import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { Splide, SplideTrack } from '@splidejs/react-splide';
import { InView } from 'react-intersection-observer';
import TestimonialSlide from './testimonial-slide';
import testimonials from '../content/testimonials.json';

const Testimonials = () => {
  const [inView, setInView] = React.useState(false)

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <InView as="section" onChange={setInView} delay="200">
      <div className="testimonials">
        <div className="container">      
          <section className={`animate${inView ? ' fade-in': ''}`}>            
            <Splide hasTrack={ false }
              options={{
                arrows: true,
                type: "loop",
                rewind: "true",
                width: '100%',
                pagination: false,
                perPage: 1,
                updateOnMove: true
              }}
            > 
              <SplideTrack> 
                {
                  testimonials.map((testimonial, i) => {
                    const image = data.allFile.edges.find(el => el.node.name === testimonial.filename);
                    return (
                      <TestimonialSlide key={i} testimonial={testimonial} image={image.node} />
                    )
                  })                
                }
              </SplideTrack>

              <div className="grid">
                <div className="testimonials__arrows splide__arrows">
                  <button className="testimonials__arrow splide__arrow splide__arrow--prev">
                    <span className="sr-only">Previous</span>
                    <svg focusable="false" aria-hidden="true" width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.82278 1.26259L2.5079 6.60896L7.82278 11.9553L6.57005 13.2155L0.00244141 6.60896L6.57005 0.00244141L7.82278 1.26259Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.10742 5.71777H32.0001V7.49989H1.10742V5.71777Z" fill="white"/>
                    </svg>
                  </button>
                  <button className="testimonials__arrow splide__arrow splide__arrow--next">
                    <span className="sr-only">Next</span>
                    <svg focusable="false" aria-hidden="true" width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M24.1772 11.9549L29.4921 6.60857L24.1772 1.26221L25.4299 0.00205612L31.9976 6.60857L25.4299 13.2151L24.1772 11.9549Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M30.8926 7.49976L-0.000141144 7.49976V5.71764L30.8926 5.71764V7.49976Z" fill="white"/>
                    </svg>
                  </button>
                </div>
              </div>
            </Splide>            
          </section>
        </div>
      </div>
    </InView>
  )
}

export default Testimonials